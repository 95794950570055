const { LOGIN_LINK_ID } = require( './constants' );
const interactionEvent = {
	event: 'fireEvent',
	eventCategory: 'user interactions',
	eventAction: 'user login',
};

const withElementor = {
	...interactionEvent,
	eventLabel: 'login attempt',
	eventLabel2: 'login with elementor',
};

const withWordpress = {
	...interactionEvent,
	eventLabel: 'login attempt',
	eventLabel2: 'login with email & password',
};

const linksToTarget = [
	[ LOGIN_LINK_ID, withElementor ],
	[ 'wp-submit', withWordpress ],
];

export function setLoginLinksGTM() {
	for ( const [ id, eventData ] of linksToTarget ) {
		const target = document.getElementById( id );
		if ( target ) {
			target.addEventListener( 'click', () => {
				window.dataLayer = window.dataLayer || [];
				window.dataLayer.push( eventData );
			} );
		}
	}
}
